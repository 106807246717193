import BoxAnim from "../../components/BoxAnim";
import GoldTokenImg from "../../static/images/gold-coin-big.png";

const PlatformDetails = () => {

   return(
      <section className='platform-details-section d-md-none'>
         <div className='platform-details-container'>
            <div className='d-md-flex align-items-center justify-content-center '>
               <div className='detail-points-wrapper'>
                  <BoxAnim className='item'>
                     <div className='title'>Platform Fees</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
                  </BoxAnim>
                  <BoxAnim className='item middle-left'>
                     <div className='title'>Platform Vaults</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
                  </BoxAnim>
                  <BoxAnim className='item'>
                     <div className='title'>Investor Tiers</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
                  </BoxAnim>
               </div>

               <BoxAnim className='token-img-container'>
                  <img src={GoldTokenImg} alt='' />
               </BoxAnim>

               <div className='detail-points-wrapper'>
                  <BoxAnim className='item'>
                     <div className='title'>Asset Backed Lending</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
                  </BoxAnim>
                  <BoxAnim className='item middle-right'>
                     <div className='title'>Gas Conversion</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
                  </BoxAnim>
                  <BoxAnim className='item'>
                     <div className='title'>Digital Board </div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
                  </BoxAnim>
               </div>
            </div>
         </div>
      </section>
   )
}
export default PlatformDetails;