import { useEffect, useState } from 'react';
import { VictoryPie } from 'victory';
import BoxAnim from '../../components/BoxAnim';

const TokenDistributionSection = () => {

  const [processedData, setProcessedData] = useState([]);
  
  useEffect(() => {
    const data = [
      { x: 1, y: 2, label: "Team" },
      { x: 2, y: 3, label: "Public" },
      { x: 3, y: 5, label: "Rewards and Airdrops" },
      { x: 4, y: 5, label: "Private" }
    ]

    let total = 0;
    
    data.forEach((item) => {
      total += item.y
    });

    let processed_data = []

    data.forEach((item) => {

      const percentage = parseFloat(Number((item.y / total) * 100).toFixed(2));
      const label = `${item.label} - ${percentage}%`;
      processed_data.push({
        x : item.x,
        y : item.y,
        label : label
      })
    });

    setProcessedData(processed_data);

  }, [])

  return(
    <section className='token-distribution-section'>
        <div className='site-container'>
          <div className='title-container'>
              <BoxAnim className='title'> Token <span>Distribution</span> </BoxAnim>
              <BoxAnim className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </BoxAnim>
          </div>

          <BoxAnim className='chart-container'>
            <VictoryPie
              height={300}
              data={processedData}
              colorScale={["#C6D2FD", "#4A3AFF", "#4A3AFF", "#93AAFD"]}
              innerRadius={65}
              padAngle={3}
              cornerRadius={5}
              labels={["10%"]}
              style={{
                labels : {
                  fill: "#CADCFF",
                  fontSize : 8,
                  fontWeight : 500
                }
              }}
            />
          </BoxAnim>
        </div>
    </section>
  )
}
export default TokenDistributionSection;