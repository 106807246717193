import { useEffect } from "react";
// import rolly from "rolly.js";
import LocomotiveScroll from 'locomotive-scroll';

const ScrollContainer = ({ children }) => {
  
  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth : true,
      multiplier : 0.35
    });
    // const r = rolly({
    //   view: document.querySelector('#root'),
    //   native: true,
    //   ease : "0.06"
    // });
    // r.init();
    
    // return () => {
    //   r.destroy();
    // }

    scroll.on('scroll', (args) => {

      // To select tokens section
      const tokensSection = args.currentElements['tokens-container']
      

      if(tokensSection) {
        let progress = tokensSection.progress * 100;
        const token = document.getElementById("animate-token");

        const token_detail_left_top = document.getElementById("token-detail-left-top");
        const token_detail_left_middle = document.getElementById("token-detail-left-middle");
        const token_detail_left_bottom = document.getElementById("token-detail-left-bottom");
        const token_detail_right_top = document.getElementById("token-detail-right-top");
        const token_detail_right_middle = document.getElementById("token-detail-right-middle");
        const token_detail_right_bottom = document.getElementById("token-detail-right-bottom");

        if(progress >= 6){
          token.style.opacity = 1;
        }
        if(progress < 6){
          token.style.opacity = 0;
        }

        if(progress > 20 && progress <= 40){
          const scale = (progress / 40);
          token.style.scale = scale;
        }

        if(progress > 76){
          const diff = 76 - progress;
          token.style.marginTop = `${diff * 35}px`;
        }

        // Platform Fees
        if(progress >= 45){
          token_detail_left_top.classList.add("active");
        }
        else if(progress < 45){
          token_detail_left_top.classList.remove("active");
        }

        // Asset Backed Lending
        if(progress >= 50){
          token_detail_right_top.classList.add("active");
        }
        else if(progress < 50){
          token_detail_right_top.classList.remove("active");
        }

        // Platform Vaults
        if(progress >= 55){
          token_detail_left_middle.classList.add("active");
        }
        else if(progress < 55){
          token_detail_left_middle.classList.remove("active");
        }

        // Gas Conversion
        if(progress >= 60){
          token_detail_right_middle.classList.add("active");
        }
        else if(progress < 60){
          token_detail_right_middle.classList.remove("active");
        }

        // Investor Tiers
        if(progress >= 65){
          token_detail_left_bottom.classList.add("active");
        }
        else if(progress < 65){
          token_detail_left_bottom.classList.remove("active");
        }

        // Digital Board
        if(progress >= 70){
          token_detail_right_bottom.classList.add("active");
        }
        else if(progress < 70){
          token_detail_right_bottom.classList.remove("active");
        }
      }
    });
    
    return () => {
      scroll.destroy();
    }
  }, [])

  return(
    <div data-scroll-container>
      {children}
    </div>
  )
}

export default ScrollContainer;