import {motion} from "framer-motion";

const PageWrapperAnim = () => {
  return(
    <motion.div
      initial = {{
        scaleY : 1,
        transformOrigin : "top center"
      }}
      animate = {{
        scaleY : 0,
        transformOrigin : "top center"
      }}
      exit = {{
        scaleY : 1,
        transformOrigin : "bottom center"
      }}
      transition={{
        duration : 0.75,
        ease: "easeInOut"
      }}
      className="page-loader-wrapper"
    >
    </motion.div>
  )
}

export default PageWrapperAnim;