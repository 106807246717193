import BoxAnim from "../../components/BoxAnim";
import BoxImage from "../../static/images/box-animation.gif";

const Tokenomics = () => {

	return(
		<section className='tokenomics-section'>
			<div className="site-container">
				<div className='tokenomics-item'>
					<div className='tokenomics-item-wrapper'>
						<div delay={0.2}>
							<img src={BoxImage} alt='' className='box-anim-gif d-md-none' />
						</div>
						<BoxAnim className='title'>
							Tokenomics
						</BoxAnim>
						<BoxAnim className='tokenomics-item-wrapper-description' delay={0.5}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris. <br /><br />
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
						</BoxAnim>
						<BoxAnim component="button" className='learn-more-button'>
							Learn More
						</BoxAnim>
					</div>

					<div className='d-md-flex d-none align-items-center justify-content-center' delay={0.8}>
						<img src={BoxImage} alt='' className='box-anim-gif' />
					</div>
				</div>
			</div>
		</section>
	)
}
export default Tokenomics;