import BoxAnim from "../../components/BoxAnim";
import {ReactComponent as PlusIcon} from "../../static/icons/plus-icon.svg"; 
import { useState } from "react";

const FAQs = [
   {
      question: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?",
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris. '
   },
   {
      question: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?",
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris. '
   },
   {
      question: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?",
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris. '
   },
   {
      question: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?",
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris. '
   },
]

const FAQ = () => {

   const [active, setActive] = useState('');

   return(
      <section className='faq-section'>
         <div className='site-container'>
            <BoxAnim className='title text-center'> Frequently Asked <span> Questions </span> </BoxAnim>

            {
               FAQs.map((item, ind) => (
                  <BoxAnim 
                     className={`faq-accordian ${ind === active ? 'active' : ''}`} 
                     onClick={() => {ind === active ? setActive('') : setActive(ind) }}
                     key={`faq-${ind}`}
                  >
                     <div className='faq-accordian-title d-flex justify-content-between align-items-center'>
                        <div>
                           {item.question}
                        </div>
                        <PlusIcon />
                     </div>

                     <div className='faq-answer-wrapper'>
                        {item.answer}
                     </div>
                  </BoxAnim>
               ))
            }
         </div>
      </section>
   )
}
export default FAQ;