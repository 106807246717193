import UtilityToken from "../../static/images/utility-token.png";
import SecurityToken from "../../static/images/security-token.png";
import GasToken from "../../static/images/gas-token.png";
import BoxAnim from "../../components/BoxAnim";

const TokenSection = () => {

  return(
    <section
      data-scroll
      data-scroll-id = "tokens-container"
      className="position-relative tokens-cards-section d-none d-md-block"
      style={{ height : "2500px" }}
      id="tokens-cards-section"
    >
      <img
        src={UtilityToken}
        alt=''
        className='animate-token'
        id="animate-token"
        data-scroll
        data-scroll-sticky
        data-scroll-target = "#tokens-cards-section"
        data-scroll-offset = "-450"
      />
      <div className="site-container">
        <div className='d-md-flex justify-content-between align-items-center token-cards-wrapper'>
          <BoxAnim className='token-card'>
              <img src={SecurityToken} alt='' className='token-img' />
              <div className='title'> Security Token </div>
              <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
              <div className='pill'> $PROP<span>S</span></div>
          </BoxAnim>
          <BoxAnim className='token-card big' id = "token-center-card">
              {/* <img src={UtilityToken} alt='' className='token-img' /> */}
              {/* To fill gap of image */}
              <div style={{ height : "129px" }}></div>
              <div className='title'> Utility Token </div>
              <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
              <div className='pill'> $PROP<span>C</span></div>
          </BoxAnim>
          <BoxAnim className='token-card'>
              <img src={GasToken} alt='' className='token-img' />
              <div className='title'> Gas Token </div>
              <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
              <div className='pill'> $PROP<span>G</span></div>
          </BoxAnim>
        </div>
      </div>

      <div
        className='platform-details-container'
        data-scroll
        data-scroll-sticky
        data-scroll-target = "#tokens-cards-section"
        data-scroll-offset = "420"
      >
        <div className='d-md-flex align-items-center justify-content-center '>
            <div className='detail-points-wrapper'>
              <div className='item' id="token-detail-left-top">
                <div className='title'>Platform Fees</div>
                <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
              </div>
              <div className='item middle-left' id="token-detail-left-middle">
                <div className='title'>Platform Vaults</div>
                <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
              </div>
              <div className='item' id="token-detail-left-bottom">
                <div className='title'>Investor Tiers</div>
                <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
              </div>
            </div>

            <div className='token-img-container'>
              {/* <img src={GoldTokenImg} alt='' /> */}
            </div>

            <div className='detail-points-wrapper'>
              <div className='item' id="token-detail-right-top">
                <div className='title'>Asset Backed Lending</div>
                <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
              </div>
              <div className='item middle-right' id="token-detail-right-middle">
                <div className='title'>Gas Conversion</div>
                <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
              </div>
              <div className='item' id="token-detail-right-bottom">
                <div className='title'>Digital Board </div>
                <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  )
}

export default TokenSection;