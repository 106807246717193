import BoxImage from "../../static/images/box-animation.gif";
import Bloomberg from "../../static/images/bloomberg.png";
import DeTelegraaf from "../../static/images/de-telegraaf.png";
import YahooFinance from "../../static/images/yahoo-finance.png";
import RTL from "../../static/images/rtl.png";
import BoxAnim from "../../components/BoxAnim";

const TopSection = () => {
  return(
    <section className="top-section">
      <div className="site-container">
        <div className="d-md-flex justify-content-between">
          
          <div className="title-container">
            <BoxAnim className="title d-flex flex-wrap">
              Digital Real Estate <span className = "white-text">Ecosystem</span>
            </BoxAnim>
            <BoxAnim className="description">
              Unlocking new technical pathways for buyers, sellers and investors.
            </BoxAnim>

            <BoxAnim className="d-flex buttons-container">
              <button component="button" className="launch-dapp-button">
                Launch Dapp
              </button>
              <button component="button" className="buy-propc-button">
                Buy PROPC
              </button>
            </BoxAnim>
          </div>
          <div className='w-100 d-flex align-content-center justify-content-center'>
            <img src={BoxImage} alt="img-fluid" className="box-animation-gif"/>
          </div>
        </div>

        <BoxAnim className="featured-container">
          <div className="title">
            As Featured in
          </div>

          <div className="d-md-flex justify-content-center justify-content-md-between align-items-center logos-container">
            <img src={Bloomberg} alt="" />
            <img src={DeTelegraaf} alt="" />
            <img src={YahooFinance} alt="" />
            <img src={RTL} alt="" />
          </div>
        </BoxAnim>
      </div>
    </section>
  )
}

export default TopSection;