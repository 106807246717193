import { VictoryChart, VictoryStack, VictoryBar, VictoryAxis } from 'victory';
import BoxAnim from '../../components/BoxAnim';

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

const TokenVestingSection = () => {

   return(
      <section className='token-vesting-section'>
         <div className='site-container'>
            <div className='title-container'>
               <BoxAnim className='title'> Token <span>Vesting</span> </BoxAnim>
               <BoxAnim className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </BoxAnim>
            </div>

            <BoxAnim className='chart-container'>
                <VictoryChart
                  width={600}
                  height={320}
                  minDomain={{ y : 0 }}
                  padding={{ top : 0, bottom : 30, right : 50, left : 50 }}
                  domainPadding={32}
               >
                  <VictoryAxis
                     tickFormat={(t) => `${t}`}
                     style={{
                        tickLabels : {
                           fontSize : "10px",
                           fontWeight : 400,
                           lineHeight : "18px",
                           fill : "rgba(202, 220, 255, 0.59)"
                        },
                        axis: { stroke: 'rgba(146, 145, 165, 0.59)' }
                     }}
                  />
                  <VictoryAxis
                     dependentAxis
                     tickFormat={(t) => `${formatter.format(t)}`}
                     style={{
                        tickLabels : {
                           fontSize : "10px",
                           fontWeight : 400,
                           lineHeight : "18px",
                           fill : "rgba(202, 220, 255, 0.59)"
                        },
                        axis: { stroke: 'rgba(0, 0, 0, 0)' },
                        grid : {
                           stroke : 'rgba(229, 229, 239, 0.22)',
                           strokeDasharray: 5
                        }
                     }}
                  />
                  <VictoryStack
                     colorScale={['#2D5BFF', "#4A3AFF", "#93AAFD", "#C6D2FD",  ]}
                     style={{
                        data: { width: 40 }
                     }}
                  >
                     <VictoryBar
                        data={[
                           {x: 'May 2023', y: 40000 },
                           {x: 'June 2023', y: 600000 },
                           {x: 'July 2023', y: 80000 },
                           {x: 'Aug 2023', y: 100000 },
                           {x: 'Sep 2023', y: 110000 },
                           {x: 'Oct 2023', y: 90000 },
                        ]}
                     />
                     <VictoryBar
                        data={[
                           {x: 'May 2023', y: 60000 },
                           {x: 'June 2023', y: 100000 },
                           {x: 'July 2023', y: 150000 },
                           {x: 'Aug 2023', y: 200000 },
                           {x: 'Sep 2023', y: 220000 },
                           {x: 'Oct 2023', y: 180000 },
                        ]}
                     />
                     <VictoryBar
                        data={[
                           {x: 'May 2023', y: 80000 },
                           {x: 'June 2023', y: 170000 },
                           {x: 'July 2023', y: 350000 },
                           {x: 'Aug 2023', y: 550000 },
                           {x: 'Sep 2023', y: 750000 },
                           {x: 'Oct 2023', y: 510000 },
                        ]}
                     />
                     <VictoryBar
                        cornerRadius={5}
                        data={[
                           {x: 'May 2023', y: 95000 },
                           {x: 'June 2023', y: 200000 },
                           {x: 'July 2023', y: 480000 },
                           {x: 'Aug 2023', y: 800000 },
                           {x: 'Sep 2023', y: 1000000 },
                           {x: 'Oct 2023', y: 540000 },
                        ]}
                     />
                  </VictoryStack>
               </VictoryChart>
            </BoxAnim>

            <div className='charts-label-container'>
               <BoxAnim className='d-flex align-items-center chart-label'>
                  <span className='circle' style={{ backgroundColor : "#4263A6" }}></span>
                  Team Vesting
               </BoxAnim>
               <BoxAnim className='d-flex align-items-center chart-label'>
                  <span className='circle' style={{ backgroundColor : "#6C90D9" }}></span>
                  Public Vesting
               </BoxAnim>
               <BoxAnim className='d-flex align-items-center chart-label'>
                  <span className='circle' style={{ backgroundColor : "#CADCFF" }}></span>
                  Private Sale
               </BoxAnim>
               <BoxAnim className='d-flex align-items-center chart-label'>
                  <span className='circle' style={{ backgroundColor : "#FFFFFF" }}></span>
                  Airdrops and Rewards
               </BoxAnim>
            </div>
         </div>
      </section>
   )
}
export default TokenVestingSection;