import BoxAnim from "../../components/BoxAnim";
import BoxImage from "../../static/images/box-animation.gif";

const DigitalBoardSection = () => {

   return(
      <section className='digital-board-section'>
         <div className='site-container'>
            <div className='box-items'>
               <BoxAnim className='item-box'>
                  <div>
                     <div className='title'>Digital Board</div>
                     <div className='description'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                     </div>
                     <div className='learn-more'> Learn More </div>
                  </div>
               </BoxAnim>
               <BoxAnim className='item-box'>
                  <div>
                     <div className='title'>Ambassadors</div>
                     <div className='description'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                     </div>
                     <div className='learn-more'> Learn More </div>
                  </div>
                  <div className='d-flex justify-content-end w-100'>
                     <img src={BoxImage} alt='' className='box-animation-gif' />
                  </div>
               </BoxAnim>
            </div>
         </div>
      </section>
   )
}
export default DigitalBoardSection;