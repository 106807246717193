import Header from "../../components/Header";
import TopSection from "./TopSection";
import Footer from "../../components/Footer";
import TokenTypes from "./TokenTypes";
import ScrollContainer from "../../components/ScrollContainer";
import TokenVestingSection from "./TokenVestingSection";
import PlatformDetails from "./PlatformDetails";
import RoadmapSection from "./RoadmapSection";
import TokenDistributionSection from "./TokenDistributionSection";
import PageWrapperAnim from "../../components/PageWrapperAnim";
import TokenSection from "./TokenSections";

const Tokenomics = () => {

   return(
      <>
         <Header showDappWrapper={false} />
         <PageWrapperAnim />
         <ScrollContainer>
            <TopSection />
            <TokenTypes />
            <TokenSection />
            <PlatformDetails />
            <TokenVestingSection />
            <TokenDistributionSection />
            <RoadmapSection />
            <Footer />
         </ScrollContainer>
      </>
   )
}
export default Tokenomics;