import { useState } from "react";
import BoxImage from "../../static/images/box-animation.gif";
import BoxAnim from "../../components/BoxAnim";

const INVESTMENT_APP_TABS = [
  "Real Estate",
  "Crypto",
  "Secondary Market",
  "Asset-backed Card"
]

const DAPP_TABS = [
  "Staking",
  "Vaults",
  "Lending/Borrow"
]

const PropchainEcosystemSection = () => {

  const [selected_investment_tab, set_selected_investment_tab] = useState(INVESTMENT_APP_TABS[0]);
  const [selected_dapp_tab, set_selected_dapp_tab] = useState(DAPP_TABS[0]);

  return(
    <section className="propchain-ecosystem-section">
      <div className="site-container">
        <BoxAnim className="title">
          Propchain <span>Ecosystem</span>
        </BoxAnim>
        <BoxAnim className="description mx-auto" delay={0.5}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </BoxAnim>

        <div className="number-wrapper">

          <BoxAnim className="number-container text-center">
            <div className="number">
              $1M+
            </div>
            <div className="label">
              Tokenised Assets
            </div>
          </BoxAnim>

          <BoxAnim className="number-container text-center" delay={0.2}>
            <div className="number">
              1000+
            </div>
            <div className="label">
              Users
            </div>
          </BoxAnim>

          <BoxAnim className="number-container text-center" delay={0.4}>
            <div className="number">
              $20M+
            </div>
            <div className="label">
              Market Cap
            </div>
          </BoxAnim>

          <BoxAnim className="number-container text-center" delay={0.6}>
            <div className="number">
              2500+
            </div>
            <div className="label">
              Holders
            </div>
          </BoxAnim>

        </div>

        <div className="ecosystem-items-row">
          <BoxAnim className="ecosystem-item-box full-width">

            <div className="d-md-flex">
              <div className="details-column">
                <div className="ecosystem-item-box-title">
                  Propchain <span>Investment App</span>
                </div>

                <div className="tabs-container">
                  {
                    INVESTMENT_APP_TABS.map((tab_value, ind) => {
                      const is_tab_active = tab_value === selected_investment_tab;
                      return(
                        <div
                          className={`tab d-flex align-items-center ${is_tab_active ? "active" : ""}`}
                          key={ind}
                          onClick={() => set_selected_investment_tab(tab_value)}
                          >
                          {is_tab_active && <span className="dot"></span>} {tab_value}
                        </div>
                      )
                    })
                  }
                </div>
                
                <img src={BoxImage} alt="" className='box-anim-gif d-md-none' />

                <div className="ecosystem-item-box-description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
                </div>

                <button>
                  Launch Platform 
                </button>
              </div>
              <div className="d-none d-md-flex align-items-end justify-content-center flex-grow-1">
                <img src={BoxImage} alt="" className='box-anim-gif mt-5 mt-md-0' />
              </div>
            </div>
          </BoxAnim>

          <BoxAnim className="ecosystem-item-box full-width">

            <div className="d-md-flex">
              <div className="d-md-flex d-none align-items-end justify-content-center flex-grow-1">
                <img src={BoxImage} alt="" className='box-anim-gif mb-5 mb-md-0' />
              </div>
              <div className="details-column">
                <div className="ecosystem-item-box-title">
                  Propchain <span>DApp</span>
                </div>

                <div className="tabs-container d-flex">
                  {
                    DAPP_TABS.map((tab_value, ind) => {
                      const is_tab_active = tab_value === selected_dapp_tab;
                      return(
                        <div
                          className={`tab d-flex align-items-center ${is_tab_active ? "active" : ""}`}
                          key={ind}
                          onClick={() => set_selected_dapp_tab(tab_value)}
                        >
                          {is_tab_active && <span className="dot"></span>} {tab_value}
                        </div>
                      )
                    })
                  }
                </div>
                
                <img src={BoxImage} alt="" className='box-anim-gif d-md-none' />

                <div className="ecosystem-item-box-description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
                </div>

                <button>
                  Launch Platform 
                </button>

              </div>
            </div>
          </BoxAnim>

          <BoxAnim className="ecosystem-item-box full-width-box">
            <div className="d-flex">
              <div className="coming-soon-label">
                Coming Soon
              </div>
            </div>

            <img src={BoxImage} alt="" className="box-animation-gif" />

            <div className="ecosystem-item-box-title">
              Propchain <span> Protocol </span>
            </div>

            <div className="ecosystem-item-box-description mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            </div>
          </BoxAnim>

          <BoxAnim className="ecosystem-item-box full-width-box">
            <div className="d-flex">
              <div className="coming-soon-label">
                Coming Soon
              </div>
            </div>

            <img src={BoxImage} alt="" className="box-animation-gif" />

            <div className="ecosystem-item-box-title">
              Propchain <span> Tools </span>
            </div>

            <div className="ecosystem-item-box-description mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            </div>
          </BoxAnim>

        </div>
      </div>
    </section>
  )
}

export default PropchainEcosystemSection;