import {ReactComponent as Logo} from "../static/images/Logo.svg";
import {ReactComponent as Twitter} from "../static/icons/twitter.svg";
import {ReactComponent as Telegram} from "../static/icons/telegram.svg";
import {ReactComponent as Instagram} from "../static/icons/instagram.svg";
import {ReactComponent as Discord} from "../static/icons/discord.svg";
import {ReactComponent as Linkedin} from "../static/icons/linkdein.svg"
import { Link } from "react-router-dom";

const FooterLinks = [
   {
      title: "Platform",
      links: [
         { link: "/", label: "Real Estate" },
         { link: "/", label: "Crypto" },
         { link: "/", label: "Secondary Market" },
         { link: "/", label: "Asset backed Card" },
      ]
   },
   {
      title: "DApp",
      links: [
         { link: "/", label: "Staking" },
         { link: "/", label: "Vaults" },
         { link: "/", label: "Digital Boards" },
         { link: "/", label: "Claims" },
         { link: "/", label: "Borrow/Lending" },
      ]
   },
   {
      title: "Developers",
      links: [
         { link: "/", label: "Docs" },
         { link: "/", label: "Bug Bounty" },
         { link: "/", label: "GitHub" },
         { link: "/", label: "Forum" },
      ]
   },
   {
      title: "About",
      links: [
         { link: "/", label: "Community" },
         { link: "/", label: "Company" },
         { link: "/", label: "Tokenomics" },
         { link: "/", label: "Digital Board" },
         { link: "/", label: "Ambassadors" },
      ]
   },
   {
      title: "General",
      links: [
         { link: "/", label: "Careers" },
         { link: "/", label: "Sustainability" },
         { link: "/", label: "Contact" }
      ]
   },
]

const Footer = () => {

   return(
      <>
         <footer>
            <div className='footer-container'>
               <div className='d-md-flex align-items-center justify-content-between'>
                  <div className='footer-logo'>
                     <Logo />
                  </div>
                  <div className='d-flex align-items-center justify-content-between social-links-container'>
                     <Link to='https://www.google.com' target="_blank" rel="noopener noreferrer">
                        <Twitter />
                     </Link>
                     <Link to='https://www.google.com' target="_blank" rel="noopener noreferrer">
                        <Telegram />
                     </Link>
                     <Link to='https://www.google.com' target="_blank" rel="noopener noreferrer">
                        <Instagram />
                     </Link>
                     <Link to='https://www.google.com' target="_blank" rel="noopener noreferrer">
                        <Discord />
                     </Link>
                     <Link to='https://www.google.com' target="_blank" rel="noopener noreferrer">
                        <Linkedin />
                     </Link>
                  </div>
               </div>

               <div className='links-wrapper'>
                  {
                     FooterLinks.map((item, ind) => (
                        <div key={ind}>
                           <div className='title'>{item.title}</div>

                           {
                              item.links.map((cta, index) => (
                                 <Link 
                                    to={cta.link} 
                                    className='links' 
                                    key={index}
                                    target="_blank"
                                    rel="noopener noreferrer"

                                 >
                                    {cta.label}
                                 </Link>
                              ))
                           }
                        </div>
                     ))
                  }
               </div>

               <div className='tc-wrapper d-md-flex align-items-center justify-content-md-between justify-content-center'>
                  <div className='rights-text'>
                     © 2023 Propchain | All Rights Reserved
                  </div>
                  <div className='d-flex align-items-center justify-content-center'>
                     <Link 
                        to="/"
                        className='privacy-link' 
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Privacy
                     </Link>
                     <Link 
                        to="/"
                        className='privacy-link' 
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Terms & Conditions
                     </Link>
                  </div>
               </div>
            </div>
         </footer>
      </>
   )
}
export default Footer;