import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from "./views/Home";
import Tokenomics from "./views/Tokenomics";
import { AnimatePresence } from "framer-motion";

const RoutesContainer = () => {
  const location = useLocation()
  return(
    <AnimatePresence mode="wait" initial = {true}>
      <Routes location={location} key={location.pathname}>
        <Route index path="/" element={<Home />} />
        <Route index path="/tokenomics" element={<Tokenomics />} />
      </Routes>
    </AnimatePresence>
  )
}

const RoutesConfig = () => {
  return(
    <Router>
      <RoutesContainer />
    </Router>
  )
}

export default RoutesConfig;