import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopSection from "./TopSection";
import InnovationSection from "./InnovationSection";
import PropchainEcosystemSection from "./PropchainEcosystemSection";
import TokenomicsSection from "./TokenomicsSection";
import DigitalBoardSection from "./DigitalBoardSection";
import FAQ from "./FAQ";
import ScrollContainer from "../../components/ScrollContainer";
import PageWrapperAnim from "../../components/PageWrapperAnim";

const Home = () => {
  return(
    <>
      <Header showDappWrapper={true} />
      <PageWrapperAnim />
      <ScrollContainer>
        <TopSection />
        <InnovationSection />
        <PropchainEcosystemSection />
        <TokenomicsSection />
        <DigitalBoardSection />
        <FAQ />
        <Footer />
      </ScrollContainer>
    </>
  )
}

export default Home;