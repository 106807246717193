import BoxAnim from "../../components/BoxAnim";
import UtilityToken from "../../static/images/utility-token.png";
import SecurityToken from "../../static/images/security-token.png";
import GasToken from "../../static/images/gas-token.png";

const TokenTypes = () => {

   return(
      <div className='token-types-section'>
         <div className='site-container'>
            <BoxAnim className='title-container'>
               <div className='title'>
                  <span>Token</span> Types
               </div>
               <div className="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
               </div>
            </BoxAnim>

            <div className='d-md-none justify-content-between align-items-center token-cards-wrapper'>
               <BoxAnim className='token-card'>
                  <img src={SecurityToken} alt='' className='token-img' />
                  <div className='title'> Security Token </div>
                  <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
                  <div className='pill'> $PROP<span>S</span></div>
               </BoxAnim>
               <BoxAnim className='token-card big'>
                  <img src={UtilityToken} alt='' className='token-img' />
                  <div className='title'> Utility Token </div>
                  <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
                  <div className='pill'> $PROP<span>C</span></div>
               </BoxAnim>
               <BoxAnim className='token-card'>
                  <img src={GasToken} alt='' className='token-img' />
                  <div className='title'> Gas Token </div>
                  <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
                  <div className='pill'> $PROP<span>G</span></div>
               </BoxAnim>
            </div>
         </div>

         {/* <div className='platform-details-container'>
            <div className='d-md-flex align-items-center justify-content-center '>
               <div className='detail-points-wrapper'>
                  <div className='item'>
                     <div className='title'>Platform Fees</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
                  </div>
                  <div className='item middle-left'>
                     <div className='title'>Platform Vaults</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
                  </div>
                  <div className='item'>
                     <div className='title'>Investor Tiers</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
                  </div>
               </div>

               <div className='token-img-container'>
                  <img src={GoldTokenImg} alt='' />
               </div>

               <div className='detail-points-wrapper'>
                  <div className='item'>
                     <div className='title'>Asset Backed Lending</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
                  </div>
                  <div className='item middle-right'>
                     <div className='title'>Gas Conversion</div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  </div>
                  </div>
                  <div className='item'>
                     <div className='title'>Digital Board </div>
                     <div className='description'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </div>
                  </div>
               </div>
            </div>
         </div> */}

      </div>
   )
}
export default TokenTypes;