import { useState } from "react";
import { Link } from 'react-router-dom';
import {ReactComponent as ExternalLink} from "../static/icons/external-link.svg";
import {ReactComponent as Logo} from "../static/images/Logo.svg";
import {ReactComponent as CaretDown} from "../static/icons/caret-down.svg";
import {ReactComponent as Twitter} from "../static/icons/twitter.svg";
import {ReactComponent as Telegram} from "../static/icons/telegram.svg";
import {ReactComponent as Instagram} from "../static/icons/instagram.svg";
import {ReactComponent as Discord} from "../static/icons/discord.svg";
import {ReactComponent as Cross} from "../static/icons/cross.svg";
import {ReactComponent as Hamburger} from "../static/icons/hamburger.svg";

const Header = ({ showDappWrapper, isHome }) => {

  const [showSidebar, setShowSidebar] = useState(false);
  return(
    <>
      {
        showDappWrapper &&
        <div className="position-fixed top-notification-bar d-flex align-items-center justify-content-center">
          Introducing dApp 2.0: Real Stake for Real Estate <a href="https://app.propchain.com/" target="_blank" rel="noreferrer noopener" className="d-inline-flex align-items-center"> <span>Learn more</span> <ExternalLink /> </a>
        </div>
      }
      <header className={`${showDappWrapper ? '' : 'top-34'}`}>
        <div className={`site-container`}>
          <div className={`mobile-header-wrapper d-md-none ${showDappWrapper ? '' : 'top-34'} `}>
            <Link to='/'>
              <Logo />
            </Link>
            <div onClick={() => setShowSidebar(true)}>
              <Hamburger />
            </div>
          </div>
          <div className={`header-container d-flex align-items-md-center justify-content-between flex-column flex-md-row ${showSidebar ? 'active' : ''}`}>
            <div>
              <div className='sidebar-px d-flex align-items-center justify-content-between'>
                <Link to='/'>
                  <Logo />
                </Link>

                <div className='d-md-none' onClick={() => setShowSidebar(false)}>
                  <Cross />
                </div>
              </div>
              <div className="links-container d-md-none">
                <div className="link d-flex align-items-center">
                  Products
                </div>
                <Link to='/tokenomics' className="link d-flex align-items-center">
                  Tokenomics
                </Link>
                <div className="link d-flex align-items-center">
                  About
                </div>
              </div>
            </div>

            <div className="links-container d-none d-md-grid">
              <div className="link d-flex align-items-center justify-content-center">
                Products <CaretDown />
              </div>
              <Link to='/tokenomics' className="link d-flex align-items-center justify-content-center">
                Tokenomics
              </Link>
              <div className="link d-flex align-items-center justify-content-center">
                About <CaretDown />
              </div>
            </div>

            <div className="d-flex align-items-md-center flex-column flex-md-row sidebar-px" style={{ gap : "14px" }}>
              <button>
                Buy PROPC
              </button>

              <div className="divider d-none"></div>

              <div className="d-flex align-items-center justify-content-between social-icons-container d-md-none">
                <Twitter/>
                <Telegram />
                <Instagram />
                <Discord />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header;