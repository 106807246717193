import { motion } from "framer-motion";

const MotionComponent = ({ component, children, ...props }) => {
  switch(component){
    case "span" : 
      return <motion.span {...props}> {children} </motion.span>
    case "button" : 
      return <motion.button {...props}> {children} </motion.button>
    default : 
      return <motion.div {...props}> {children} </motion.div>
  }
}

const BoxAnim = ({children, delay = 0, component, ...props}) => {
  return(
    <MotionComponent
      initial={{ opacity: 0, transform : "translateY(50px) scale(1.1)", transformOrigin : "bottom" }}
      whileInView={{ opacity: 1, transform : "translateY(0px) scale(1)",  transformOrigin : "bottom" }}
      exit={{ opacity : 1 }}
      viewport={{ once: false, amount : 0.5 }}
      transition={{ duration : 1, delay : delay }}
      component = {component}
      {...props}
    >
      {children}
    </MotionComponent>
  )
}

export default BoxAnim;