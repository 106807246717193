import BoxAnim from "../../components/BoxAnim";
import BoxImage from "../../static/images/box-animation.gif";

const InnovationSection = () => {
  return(
    <section className="innovation-section">
      <div className="site-container">
        <div className="d-md-flex justify-content-between">
          <BoxAnim
            className="title-container"
          >
            <div className="title">
              Innovation <br />
              <span> in Real Estate </span>
            </div>

            <div className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            </div>

            <button>
              Learn More
            </button>
          </BoxAnim>

          <div className="d-flex justify-content-center align-items-center flex-grow-1">
            <img src={BoxImage} alt="" className='box-animation-gif' />
          </div>

        </div>
      </div>
    </section>
  )
}

export default InnovationSection;