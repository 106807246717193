import { useEffect, useState } from "react";
import { ReactComponent as CaretRight } from "../../static/icons/caret-right.svg";
import BoxAnim from "../../components/BoxAnim";

const RoadmapData = [
   {
      title : "Phase 1",
      description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
   },
   {
      title : "Phase 2",
      description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
   },
   {
      title : "Phase 3",
      description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
   },
   {
      title : "Phase 4",
      description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
   },
   {
      title : "Phase 5",
      description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
   },
   {
      title : "Phase 6",
      description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
   }
];

let rotateCount = 0;
let roadmapTimeout;

const RoadmapSection = () => {

   const [current_step, set_current_step] = useState(0);

   // To rotate ring

   useEffect(() => {
      return () => {
         rotateCount = 0;
         clearTimeout(roadmapTimeout)
      }
   }, [])

   useEffect(() => {

      roadmapTimeout = setTimeout(() => {
         let nextStep = current_step + 1;
         if(nextStep === RoadmapData.length){
            nextStep = 0;
         }
         set_current_step(nextStep);

         rotateRing("up")
      }, 3000)

      return () => {
         clearTimeout(roadmapTimeout);
      }
   }, [current_step])

   const handleClickNext = () => {
      let nextStep = current_step + 1;
      if(current_step === RoadmapData.length - 1){
         nextStep = 0;
      }
      set_current_step(nextStep);
      rotateRing("up")
   }

   const handleClickPrev = () => {
      let prevStep = current_step - 1;
      if(current_step === 0){
         prevStep = RoadmapData.length - 1;
      }
      set_current_step(prevStep);
      rotateRing("down")
   }

   const rotateRing = (direction) => {
      const ring = document.getElementById("roadmap-ring");
      if(direction === "up"){
         rotateCount += 1;
      }
      else{
         rotateCount -= 1;
      }
      const rotateDegrees = rotateCount * 45;
      let degrees = rotateCount < 0 ? `${Math.abs(rotateDegrees)}deg` : `${-rotateDegrees}deg`;
      ring.style.transform = `rotate(${degrees})`;
   }

   return(
      <section className='roadmap-section'>
         <div className='site-container'>
            <div className='roadmap-grid'>
               <BoxAnim className='title-container d-md-flex flex-column justify-content-center'>
                  <div className='title'>
                     Propchain <br className='d-none d-md-none' /> <span>Roadmap</span>
                  </div>
                  <div className='description'>
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                  </div>
               </BoxAnim>
               <div className="roadmap-container d-flex flex-column align-items-start justify-content-center">
                  <div className="roadmap-ring" id="roadmap-ring">
                     <div className="ring-circle top"></div>
                     <div className="ring-circle bottom"></div>
                     <div className="ring-circle left"></div>
                     <div className="ring-circle right"></div>
                     <div className="ring-circle top-right"></div>
                     <div className="ring-circle top-left"></div>
                     <div className="ring-circle bottom-right"></div>
                     <div className="ring-circle bottom-left"></div>
                  </div>

                  {
                     RoadmapData.map((item, ind) => {

                        let step_class = "";

                        if(ind === current_step){
                           step_class = "active";
                        }
                        if(ind === current_step - 1){
                           step_class = "prev"
                        }
                        if(ind === current_step - 2){
                           step_class = "prev-1"
                        }

                        if(ind === current_step + 1){
                           step_class = "next"
                        }
                        if(ind === current_step + 2){
                           step_class = "next-1"
                        }

                        // Check if this is first step
                        if(current_step === 0){
                           // Make last step as previous
                           if(ind === RoadmapData.length - 1){
                              step_class = "prev";
                           }
                           // Make second last step as previous - 1
                           if(ind === RoadmapData.length - 2){
                              step_class = "prev-1";
                           }
                        }
                        // Check if this is second step
                        if(current_step === 1){
                           // Make last step as previous - 1
                           if(ind === RoadmapData.length - 1){
                              step_class = "prev-1";
                           }
                        }

                        // Check if this is last step
                        if(current_step === RoadmapData.length - 1){
                           // Make first step as next
                           if(ind === 0){
                              step_class = "next";
                           }
                           // Make second step as next + 1
                           if(ind === 1){
                              step_class = "next-1";
                           }
                        }

                        // Check if this is second last step
                        if(current_step === RoadmapData.length - 2){
                           // Make first step as next + 1
                           if(ind === 0){
                              step_class = "next-1";
                           }
                        }

                        return(
                           <div className={`roadmap-item ${step_class}`} key={ind}>
                              <div className="title">
                                 {item.title}
                              </div>
                              <div className="description">
                                 {item.description}
                              </div>
                           </div>         
                        )
                     })
                  }
                  
                  <button
                     className="position-relative d-flex align-items-center justify-content-center ring-navigation-button prev"
                     onClick={handleClickPrev}
                  >
                     <CaretRight />
                  </button>
                  <button
                     className="position-relative d-flex align-items-center justify-content-center ring-navigation-button next"
                     onClick={handleClickNext}
                  >
                     <CaretRight />
                  </button>
               </div>
            </div>
         </div>
      </section>
   )
}
export default RoadmapSection;