import BoxAnim from "../../components/BoxAnim";
import BoxImage from "../../static/images/box-animation.gif";

const TopSection = () => {

   return(
      <section className='tokenomics-top-section'>
         <div className='site-container'>
            <div className="d-md-flex justify-content-between">
               <div className="title-container">
                  <BoxAnim className="title">
                     Propchain <span>Tokens</span>
                  </BoxAnim>
                  <BoxAnim className="description">
                     We maximise the use of tokenization, through our ecosystem powered by multiple tokens.
                  </BoxAnim>

                  <BoxAnim className="d-flex buttons-container">
                     <button className="launch-dapp-button">
                        Buy PROPC
                     </button>
                  </BoxAnim>
               </div>
               <div className='w-100 d-flex align-content-center justify-content-center'>
                  <img src={BoxImage} alt="img-fluid" className="box-animation-gif"/>
               </div>
            </div>

            <div className='matrix-wrapper'>
               <BoxAnim>
                  <div className='value'>$PROPC</div>
                  <div className='label'>Ticker</div>
               </BoxAnim>
               <BoxAnim delay={0.2}>
                  <div className='value'>$1.15</div>
                  <div className='label'>Current Price</div>
               </BoxAnim>
               <BoxAnim delay={0.4}>
                  <div className='value'>$100M</div>
                  <div className='label'>Total Supply</div>
               </BoxAnim>
               <BoxAnim delay={0.6}>
                  <div className='value'>$10.5M</div>
                  <div className='label'>Circulating Supply</div>
               </BoxAnim>
            </div>
         </div>
      </section>
   )
}
export default TopSection;